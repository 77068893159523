.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  }

  * {
    /* outline: 1px solid lime; */
    /* box-sizing: border-box; */
  }
  /* ::-webkit-scrollbar {
    width: 2px;
   } */
.rdrDefinedRangesWrapper{
  display:none !important;
}

  .apexcharts-tooltip {
    background: rgba(255, 255, 255, 0.80);
    /* color: orange; */
    font-size: 20px;
  }

  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    background-color: red;
    /* margin-top: 60px; */
  }

  /* Page content */
  #content {
  padding-top: 74px;

  }
.contentPadding {
  padding-top: 74px;
}

.addShadow {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

}
.contentPaddingForPhone {
  padding-top: 84px;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(180deg, #56b5b9 , #d6edee );
  ;
}

.apexcharts-toolbar {
  z-index: 0 !important;
  margin-right: 5px;
}

.chakra-popover__popper {
  z-index: 1 !important;
}

.loading-bar {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 4px;
  background: #ff4800;
  /* background: linear-gradient(to right, transparent, #13f5d3  30%,  #13f5d3  70%); */
  animation: loading 1s linear infinite;
  margin-top: 3px;
}

.loading-bar2 {
  width: auto;
  height: 3px;
  /* background: #ff4800; */
  background: linear-gradient(to right, transparent, #ffa986  30%,  #ff6d33  70%);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}

.skeleton {
  background-color: #eeeeee  ;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 0.8s ease infinite;
          animation: shine 0.8s ease infinite;
}
.skeletonDark {
  background-color: #6f7e85  ;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #959a9d  , rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 1s ease infinite;
          animation: shine 1s ease infinite;
}

@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

